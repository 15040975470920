.b-checkout_products {
	@include media(lg-up) {
		padding-right: rh(1);
	}

	.b-minicart_product {
		padding-bottom: rh(5);

		&-details {
			.b-minicart_product-subtotal {
				.b-product_price {
					.b-product_price-value {
						span {
							display: none;
						}
					}
				}
			}
		}
	}

	.b-minicart_product:first-child {
		margin-top: rh(5);
	}

	.b-minicart_product + .b-minicart_product {
		@include g-separator($mod: top, $spacing: #{rh(5)});
	}
}
