.b-cart_bundle {
	display: grid;
	gap: rh(4);
	grid-template-areas: 'image details';
	grid-template-columns: 120px 1fr;

	@include media(md-down) {
		grid-template-columns: 75px 1fr;
	}

	@include media(sm) {
		gap: rh(3);
	}

	&-image {
		grid-area: image;
	}

	&-image_link {
		display: block;
	}

	&-picture {
		@include g-product_image;

		img {
			@include g-product_image(img);
		}
	}

	&-details {
		grid-area: details;
	}
}
