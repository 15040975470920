/*md

# Load progress

```html_example
<div class="b-load_progress">
	<span class="b-load_progress-description">
		You've viewed 8 of 16 products
	</span>
	<progress class="b-load_progress-indicator" max="100" value="50"></progress>
</div>
```

*/

.b-load_progress {
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;

	&-description {
		font-size: $configuration-load_progress-font;
		font-weight: bold;
		margin-bottom: rh(5);
		text-align: center;
		text-transform: $configuration-load_progress-transform;
	}

	&-indicator {
		appearance: none;
		background-color: $color-progress-bg;
		border: none;
		border-radius: $size-progress-radius;
		color: $color-progress-value-bg;
		height: $size-progress-height;
		width: 100%;
	}

	/* WEBKIT */
	&-indicator::-webkit-progress-bar {
		background-color: $color-progress-bg;
		border-radius: $size-progress-radius;
	}

	&-indicator::-webkit-progress-value {
		background-color: $color-progress-value-bg;
		border-radius: $size-progress-radius;
	}

	/* MOZILLA FIREFOX */
	&-indicator::-moz-progress-bar {
		background-color: $color-progress-bg;
		border-radius: $size-progress-radius;
	}

	// IE/EDGE
	&-indicator::-ms-fill {
		background-color: $color-progress-value-bg;
		border-radius: $size-progress-radius;
	}
}
