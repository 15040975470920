.b-address_selector {
	margin: 0;

	&-actions {
		display: flex;
		justify-content: space-between;
		margin: rh(4) 0;
	}

	&-button {
		cursor: pointer;
		font-size: $size-font-alt;

		@include icon(plus, $icon-width: 12px, $icon-height: 12px) {
			display: inline-block;
			margin-right: rh(1);
		}
	}
}
