.b-order_history {
	&-find_order {
		margin-bottom: rh(8);
	}

	&-filter {
		align-items: center;
		display: flex;
		justify-content: space-between;
		margin-bottom: rh(6);

		@include media(sm) {
			flex-wrap: wrap;
		}
	}

	&-total {
		font-size: 14px;
		font-weight: 700;
		letter-spacing: 2.1px;
		text-transform: uppercase;

		@include media(sm) {
			margin-bottom: rh(6);
			text-align: center;
			width: 100%;
		}
	}

	&-recent {
        border-bottom: solid 1px;
        line-height: 32px;
        margin-bottom: 24px;
        font-size: 24px;
        font-weight: 600;
    }

	&-main {
		@include media(sm) {
			margin-top: rh(6);
		}
	}

	&-select_wrap {
		@include f-select($_mod: alt);

		align-items: center;
		display: flex;

		@include media(sm) {
			display: block;
			flex: 1;
		}

		.f-input_select {
			flex-basis: 100%;
			min-width: 220px;
		}
	}

	&-select_label {
		color: $color-text-dimmed;
		font-size: 16px;
		margin-right: rh(3);

		@include media(sm) {
			display: none;
		}
	}

	&-list {
		margin-bottom: rh(14);

		@include media(sm) {
			margin-bottom: rh(8);
		}
	}

	&-footer {
		margin: rh(6) auto;
		max-width: 400px;

		@include media(sm) {
			margin-top: rh(10);
		}

		.b-load_progress {
			margin-bottom: rh(4);
		}
	}

	.b-account_card {
		border-bottom: 1px solid $color-divider;
		padding-bottom: rh(12);

		@include media(sm) {
			margin-bottom: rh(10);
			padding-bottom: rh(10);
		}
	}
}
