.b-order_info_card {
	&-header {
		border-bottom: 1px solid $color-account_card-border;
		display: flex;
		justify-content: space-between;
		margin-bottom: rh(6);
		padding-bottom: rh(4);
	}

	&-title {
		@include t-heading_4;

		font-family: $font-main;
		font-weight: 500;
		letter-spacing: 0.35px;
	}
}
