.b-back_button {
	cursor: pointer;
	display: block;
	font-weight: 700;
	text-align: left;
	text-transform: uppercase;

	@include icon(line-arrow, $icon-width: 51px, $icon-height: 12px) {
		display: inline-block;
		margin-right: rh(1);
		transform: rotate(180deg);
		vertical-align: middle;
	}
}
