.l-account {
	@include g-section_holder;
	@include g-section_holder(narrow);

	margin-bottom: rh(22);

	@include media(sm) {
		margin-bottom: rh(10);
	}

	&-content {
		@include media(lg-up) {
			display: grid;
			gap: var(--grid-gutter);
			grid-template-columns: repeat(10, 1fr);
		}
	}

	&-aside {
		margin: 0 0 rh(6);

		@include media(lg-up) {
			grid-area: 1 / 1 / 2 / 4;
			margin: 0 rh(8) 0 0;
		}
	}

	&-main {
		@include media(lg-up) {
			grid-area: 1 / 4 / 2 / -1;
		}
	}

	&-form {
		margin: 0 auto;
		max-width: 450px;
		width: 70%;

		@include media(sm) {
			width: 100%;
		}

		.b-login_helpers {
			margin: rh(5 0 7 0);
		}

		.b-social_login {
			margin: rh(12 0 6 0);

			@include media(sm) {
				margin: rh(10 0 0 0);
			}
		}

		.b-tab_list {
			margin-bottom: rh(5);
		}

		.b-account_benefits {
			margin-bottom: rh(6);
		}
	}

	&-breadcrumbs {
		margin: rh(4 0 6 0);

		@include media(lg-up) {
			margin: rh(7 0);
		}
	}

	&.m-top_margin {
		margin-top: rh(10); // check
	}

	.b-back_button {
		margin-top: rh(4);
	}

	.b-account_title {
		margin-bottom: rh(9);

		@include media(sm) {
			margin-bottom: rh(6);
		}
	}

	.b-payment_option {
		margin-bottom: rh(5);
	}

	.b-account_card {
		margin-bottom: rh(6);
	}

	.b-account_nav {
		margin-bottom: rh(11);
	}

	.b-user_greeting {
		margin-bottom: rh(7);
	}

	&-navigation_cta {
		border: 2px solid $color-border-primary;
		color: $color-text;
		display: flex;
		font-size: 16px;
		font-weight: 700;
		height: 50px;
		justify-content: flex-start;
		line-height: 50px;
		padding: 0;
		text-transform: uppercase;
		width: 100%;

		@include media(lg-up) {
			display: none;
		}

		svg {
			border-right: 2px solid $color-border-primary;
			box-sizing: content-box;
			height: 100%;
			padding: 0 rh(3);
		}
	}

	&-navigation_cta_text {
		margin: 0 auto;
	}

	&-info_msg {
		font-size: 16px;
		margin-bottom: rh(5);

		@include media(sm) {
			text-align: center;
		}
	}

	.b-address_selector,
	.b-address_selector-back {
		margin-bottom: rh(4);
	}

	nav.b-account_nav.fav-height {
		margin-bottom: 25px !important;
	}
}
