.b-login_helpers {
	align-items: center;
	display: flex;
	justify-content: space-between;

	&-forgot_link {
		@include t-link_1;

		font-size: 16px;
	}

	.f-field {
		margin-bottom: 0;
	}
}
