/*md

# b-option_switch

This component allows user to choose one option for ex. shipping method, saved address etc.

```html_example
<div class="b-option_switch">
	<div class="b-option_switch-inner">
		<input id="shippingMethod-001" class="b-option_switch-input" name="dwfrm_shipping_shippingAddress_shippingMethodID" type="radio" value="001" data-value="001" data-ref="field" data-event-change="update">
		<div class="b-option_switch-icon"></div>
		<label class="b-option_switch-label" for="shippingMethod-001">
			<div class="b-option_switch-label_surface">
				<span class="b-option_switch-name">Ground</span>
				Free
			</div>
			<span class="b-option_switch-description">
				7-10 Business Days
			</span>
		</label>
	</div>
</div>
<div class="b-option_switch">
	<div class="b-option_switch-inner">
		<input id="shippingMethod-002" class="b-option_switch-input" name="dwfrm_shipping_shippingAddress_shippingMethodID" type="radio" value="002" data-value="002" data-ref="field" data-event-change="update" checked="">
		<div class="b-option_switch-icon"></div>
		<label class="b-option_switch-label" for="shippingMethod-002">
			<div class="b-option_switch-label_surface">
				<span class="b-option_switch-name">2-Day Express</span>
				$9.99
			</div>
			<span class="b-option_switch-description">
				2 Business Days
			</span>
		</label>
	</div>
</div>
```

*/

.b-option_switch {
	$option-indent: rh(5);

	padding: $option-indent;
	user-select: none;

	&.m-disabled {
		background-color: $color-shade_6;
		pointer-events: none;
	}

	& ~ & {
		margin-top: rh(2);
	}

	&-inner {
		display: flex;
		position: relative;
	}

	&-label {
		cursor: pointer;
		display: block;
		width: 100%;

		&::before {
			border: $global-line solid $color-divider;
			bottom: -$option-indent;
			content: '';
			left: -$option-indent;
			position: absolute;
			right: -$option-indent;
			top: -$option-indent;

			.b-option_switch-input:checked ~ & {
				border-color: $color-divider-1;
			}
		}
	}

	&-label_surface {
		align-items: flex-start;
		display: flex;
		flex-wrap: nowrap;
		justify-content: space-between;
	}

	&-edit_button {
		@include t-link_4('edit');

		position: relative;
	}

	&-label_edit {
		margin-left: rh(6);
	}

	&-input {
		@include hide(visually);
	}

	&-icon {
		@include f-radio(control);
	}

	&-input:checked + &-icon {
		@include f-radio(control, checked);
	}

	&-name {
		color: $color-checkout-switch_name-inactive;
		font-size: 12px;
		font-weight: bold;
		text-transform: uppercase;
	}

	&-input:checked ~ &-label &-name {
		color: inherit;
	}

	&-description {
		display: block;
		margin-top: rh(2);
	}

	&-promotion {
		display: inline-block;
		margin-top: rh(2);
	}

	.b-product_promo,
	.b-product_price {
		font-size: inherit;
	}
}
