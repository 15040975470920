.b-account_title {
	@include t-heading_1;

	@include media(sm) {
		text-align: center;
	}

	&.m-center {
		text-align: center;
	}
}
