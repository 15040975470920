.b-order_short_summary {
	display: flex;

	@include media(sm) {
		display: block;
	}

	&-status {
		font-weight: 700;
	}

	&-list {
		flex: 1;
	}

	&-list + &-list {
		margin-left: rh(2);

		@include media(sm) {
			margin: rh(2 0 0 0);
		}
	}

	&-list_item {
		margin-bottom: rh(2);
	}

	&-list_link {
		@include t-link_2($color-visited: $color-text);
	}
}
