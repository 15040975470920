.b-account_card {
	display: flex;
	flex-direction: column;
	overflow: hidden;
	padding-bottom: rh(6);

	&-header {
		margin-bottom: rh(3);
	}

	&-title {
		@include t-heading_4;

		border-bottom: 1px solid $color-account_card-border;
		font-family: $font-main;
		font-weight: 500;
		letter-spacing: 0.35px;
		padding-bottom: rh(4);
		text-align: left;
	}

	&-title.m-club {
		@include icon(grape, $position: after) {
			color: $color-badge-2;
			display: inline-block;
			margin-left: rh(2);
			vertical-align: middle;
		}
	}

	&-body {
		font-size: 16px;
		letter-spacing: 0.2px;
		line-height: 24px;
		margin: rh(2 0 6);

		&.m-delete_card {
			display: flex;
			justify-content: space-between;
		}

		.b-payment_methods {
			margin-left: rh(2);
		}
	}

	&-button {
		@include g-button(alt, default full_width);

		margin-bottom: rh(5);

		@include media(md-up) {
			margin-bottom: 0;
			margin-right: rh(5);
			width: 315px;
		}

		&:last-child {
			margin-bottom: 0;
			margin-right: 0;
		}
	}

	&-list_item {
		margin-bottom: rh(2);
	}

	&-list_link {
		@include t-link_2($color-visited: $color-text);
	}

	&-list_link.m-uppercase {
		text-transform: uppercase;
	}

	&-message {
		font-weight: 300;
		margin-bottom: rh(1);
	}

	&-thumbnails {
		display: flex;
		margin-top: rh(5);
	}

	&-thumbnails_item {
		margin-right: rh(2);
		max-width: 100%;
		width: 115px;

		@include media(sm) {
			max-width: 62px;
			width: 16.67%;
		}

		&:last-child {
			margin-right: 0;
		}

		&.m-additional {
			align-items: center;
			border: 2px solid $color-account_card-thumbnail-border;
			border-radius: 50%;
			color: $color-account_card-thumbnail-text;
			display: flex;
			flex-shrink: 0;
			font-size: 16px;
			font-weight: 700;
			height: 48px;
			justify-content: center;
			margin: auto 15px;
			transition: $motion-ease;
			transition-property: background-color, color;
			width: 48px;

			&:hover {
				background-color: $color-account_card-thumbnail__hover-bg;
				color: $color-account_card-thumbnail__hover-text;
				text-decoration: none;
			}
		}
	}

	&-thumbnails_picture {
		@include g-product_image;
	}

	&-thumbnails_img {
		@include g-product_image(img);
	}

	&-footer {
		align-items: center;
		display: flex;

		@include media(sm) {
			flex-direction: column;
		}
	}

	&-link {
		@include t-link_underlined($color-visited: $color-text, $font-weight: 700);

		align-self: center;
		font-size: 16px;
	}
}
