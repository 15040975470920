.b-summary_payment {
	@include t-paragraph_3;

	margin-bottom: rh(4);

	.b-payment_icon {
		margin: rh(0 1);
	}

	&-number {
		display: flex;
	}

	&-type {
		text-transform: capitalize;
	}

	.b-payment_methods {
		margin-left: rh(12);
	}
}
