.b-user_greeting {
	align-items: center;
	display: flex;

	&-message {
		color: $color-text;
		font: normal 32px/40px $font-alt;
		letter-spacing: 0.4px;
		margin-bottom: rh(2);
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		width: 100%;
	}

	&-signout {
		@include t-link_1($color-visited: $color-text);
		@include t-link_1($state: hover);

		cursor: pointer;
		font-size: 16px;
		font-weight: 600;
		letter-spacing: 0.16px;
		transition: color $motion-fast;
	}
}
