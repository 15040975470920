.b-code_field {
	&-caption {
		margin: rh(2 0 6);
	}

	&-link {
		@include t-link_underlined;
	}

	.f-field {
		margin-bottom: 0;
	}
}
