.b-cart_table {
	&-head {
		color: $color-product_table-head_text;
		display: grid;
		font-size: 16px;
		grid-gap: rh(9);
		grid-template-columns: 1fr 135px 152px;
		line-height: 26px;
		padding: rh(5 0 2);
		padding-top: 0;
		margin-top: -36px;

		@include media(md-down) {
			display: none;
			margin-bottom: 0;
		}
	}

	&-body {
		@include g-separator($mod: top, $color: $color-product_table-separator);
	}

	.b-cart_product {
		@include g-separator($color: $color-product_table-separator);

		margin-bottom: rh(6);
	}

	.b-message {
		margin-bottom: rh(4);
	}

	&.m-full_info &-head {
		grid-gap: rh(4);
		grid-template-columns: 1fr 110px 70px 170px;

		@include media(md-down) {
			display: block;
		}
	}

	&.m-full_info &-qty,
	&.m-full_info &-price,
	&.m-full_info &-total {
		text-align: center;

		@include media(md-down) {
			display: none;
		}
	}

	&.m-full_info &-total {
		text-align: right;
	}
}

.m-errortile {
	background: none;
	font-size: 14px;
	text-align: left;
	width: 600px;
	padding: 14px 21px !important;
	border: none !important;
	color: red !important;
}