@function mask-size($icon_list, $icon-number: 1) {
	$maskSize: ();

	@each $item in $icon_list {
		$value: 0;

		@if (index($icon_list, $item) == $icon-number) {
			$value: 100%;
		}

		$maskSize: append($list: $maskSize, $val: $value, $separator: comma);
	}

	@return $maskSize;
}

@function mask-url($icon_list) {
	$maskUrl: ();

	@each $item in $icon_list {
		$maskUrl: append($list: $maskUrl, $val: url('./svg-icons/sprite.svg##{$item}'), $separator: comma);
	}

	@return $maskUrl;
}

@mixin icon(
	$icon_name,
	$icon-width: 16px,
	$icon-height: 16px,
	$position: before,
	$show: pseudo
) {
	@if ($show == pseudo) {
		&::#{$position} {
			background: var(--icon-color, $color-svg-icon);
			content: '';
			display: block;
			height: $icon_height;
			mask: mask-url($icon_name);
			mask-position: 50%;
			mask-repeat: no-repeat;
			mask-size: mask-size($icon_name);
			width: $icon_width;

			@content;
		}
	}

	@if ($show == root) {
		background: var(--icon-color, $color-svg-icon);
		display: block;
		height: $icon_height;
		mask: mask-url($icon_name);
		mask-position: 50%;
		mask-repeat: no-repeat;
		mask-size: mask-size($icon_name);
		width: $icon_width;

		@content;
	}

	@if (type-of($show) == number) {
		mask-size: mask-size($icon_list: $icon_name, $icon-number: $show);
	}
}
