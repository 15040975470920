.b-account_info_tile {
	$stroke-width: 20px;

	border: 1px solid $color-divider;
	border-radius: 3px;
	display: flex;
	flex-direction: column;
	font-size: 16px;
	letter-spacing: 0.2px;
	line-height: 24px;
	min-height: 190px;
	padding: rh(5);

	@include media(sm) {
		min-height: 145px;
	}

	&-add_new {
		@include t-link_1;

		margin-top: rh(4);
		text-transform: uppercase;

		@include media(sm) {
			margin-left: rh(4);
			margin-top: 0;
		}
	}

	&-body {
		margin-bottom: rh(6);
	}

	&-footer {
		align-items: baseline;
		display: flex;
		justify-content: flex-end;
		margin-top: auto;
		order: 1;
	}

	&-title {
		font-size: 16px;
		font-weight: 600;
		margin-right: auto;

		@include icon(checkmark, $icon-width: 20px, $icon-height: 20px) {
			display: inline-block;
			margin-right: 2px;
			vertical-align: text-top;
		}
	}

	&-link {
		@include t-link_1;

		cursor: pointer;
		font-weight: 600;

		&:visited {
			@include t-link_1($state: default);
		}
	}

	&-link.m-edit {
		margin-right: rh(2);
	}

	&-link.m-set_default {
		margin-right: auto;
	}

	&-action_text {
		@include t-link_1;

		font-size: 16px;
		margin-top: rh(3);
	}

	&-info {
		font-size: 16px;
		line-height: 24px;
	}

	&-info_row {
		align-items: center;
		display: flex;
	}

	&.m-ghost {
		align-items: center;
		background: $color-account_info_tile__ghost-bg / #{$stroke-width $stroke-width};
		border: 1px solid transparent;
		color: $color-text;
		display: flex;
		flex-direction: column;
		justify-content: center;
		transition: background $motion-ease;

		&:hover {
			background: $color-account_info_tile__ghost__hover-bg / #{$stroke-width $stroke-width};
			text-decoration: none;
		}
	}

	&.m-default {
		background: $color-account_info_tile-bg;
		border: none;
	}

	.b-payment_icon {
		margin: rh(0 1 0 3);
	}
}
