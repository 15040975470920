.b-reset_password_success {
	text-align: center;

	&-title {
		font-size: 16px;
		font-weight: bold;
		margin: rh(2 0 6);
	}

	&-copy {
		margin: rh(2 0 6);
	}
}
