@import 'account-flagship_tasting_room.scss';

.b-track_notification {
	&-inner {
		background-color: $color-promo-background;
	}
	&-title {
		font-weight: 400;
	}
	&-message a{
		font-weight: 400;
	}
}

.b-account_nav-item_text {
	color: $color-secondary-background;
	font-size: 18px;
	letter-spacing: 0.2px;
	line-height: 22px;
}

.b-account_nav-item_link:after {
	height: 16px;
	width: 16px;
}

.b-account_info_tile {
	&.m-default {
		border: 1px solid $color-shade_4;
		border-radius: 0;
	}
	&-link {
		&.m-edit {
			font-weight: 400;
		}
		&.m-delete {
			font-weight: 400;
		}
		&.m-set_default {
			font-weight: 400;
		}
	}
	&-title {
		border: 1px solid $color-shade_4;
		background: $color-promo-background;
		font-weight: 400;
		font-size: 12px;
		letter-spacing: 0.3px;
		line-height: 18px;
		text-align: center;
		padding: 4px;
	}

}
