.b-payment_methods {
	column-gap: 7px;
	display: grid;
	grid-auto-columns: max-content;
	grid-auto-flow: column;

	.b-payment_icon {
		display: block;
	}
}
