.b-summary_group {
	display: grid;
	gap: 0 rh(4);
	grid-template-columns: 1fr 1fr;

	@include media(sm) {
		gap: rh(4);
		grid-template-columns: 1fr;
	}

	&-title {
		@include t-heading_6;

		margin-bottom: rh(3);
	}

	&-item.m-gift {
		word-break: break-word;
	}

	.b-expected_ship_date {
		grid-area: 2 / 2;

		@include media(sm) {
			grid-area: 3 / 1;
		}
	}
}
