.b-order_details {
	display: grid;
	grid-column-gap: rh(6);
	grid-template:
		'title'
		'short-details'
		'aside'
		'product-summary ' / 1fr;

	&-top {
		grid-area: short-details;

		.b-account_card {
			margin-bottom: 0;
			padding-bottom: 0;
		}
	}

	&-caption {
		@include t-heading_1;

		grid-area: title;
		padding-bottom: rh(9);
	}

	&-main {
		grid-area: product-summary;
		margin-bottom: rh(14);

		@include media(sm) {
			margin-bottom: 0;
		}
	}

	&-aside {
		grid-area: aside;

		.b-order_info_card {
			margin: rh(6 0 8 0);
		}
	}

	&-track_order {
		display: flex;
		justify-content: flex-end;
		margin-bottom: rh(8);

		@include media(sm) {
			margin-top: rh(6);
		}
	}

	&-track_order_btn {
		@include g-button(alt);

		@include media(sm) {
			@include g-button(alt, full_width);
		}
	}

	&.m-guest {
		margin-top: rh(6);

		.b-cart_table.m-full_info .b-cart_table-head {
			grid-template-columns: 1fr 200px 150px 170px;
		}

		.b-cart_product.m-full_info {
			@include media(lg-up) {
				grid-template:
					[start details-s image-s price-s qty-s total-s]
					auto
					[bundle-s details-e price-e qty-e total-e]
					minmax(0, 1fr)
					[bundle-e image-e message-s]
					auto
					[end message-e]
					/
					[start image-s message-s]
					115px
					[image-e bundle-s details-s]
					minmax(0, 1fr)
					[details-e price-s]
					200px
					[price-e qty-s]
					150px
					[bundle-e qty-e total-s]
					170px
					[end total-e message-e];
			}
		}
	}

	&.m-guest &-top {
		@include media(lg-up) {
			display: flex;
			justify-content: space-between;
		}

		.b-account_card {
			flex: 1;
		}
	}

	&.m-guest &-caption {
		text-align: center;
	}

	&.m-guest &-aside {
		@include media(lg-up) {
			max-width: 65%;
		}
	}
}
