.b-load_more {
	text-align: center;

	&-button {
		@include g-button(alt);

		padding: rh(0 6);
		width: 100%;
	}
}
