.b-navigation_panel {
	padding: 0;

	@include media(lg-up) {
		display: block;
		position: static;
		visibility: visible;
		z-index: initial;
	}

	@include media(md-down) {
		&.m-opened {
			overflow-y: hidden;
		}
	}

	&::after {
		@include media(lg-up) {
			display: none;
		}
	}

	&-inner {
		@include media(md-down) {
			background-color: $color-navigation_panel-background;
			bottom: 0;
			height: 100%;
			left: 0;
			max-width: 375px;
			overflow-y: auto;
			padding: rh(6 4 14);
			position: fixed;
			top: 0;
			transform: translateX(-100%);
			transition: $motion-ease-panels;
			transition-property: visibility, transform;
			visibility: hidden;
			width: 100%;
			z-index: z(modal);

			&.m-active {
				box-shadow: $depth-3;
				transform: translateX(0);
				visibility: visible;
			}
		}
	}

	&-title {
		@include t-heading_4;

		display: block;
		margin-bottom: rh(8);

		@include media(md-down) {
			display: none;
		}
	}

	.b-dialog-header {
		@include media(lg-up) {
			display: none;
		}
	}
}
