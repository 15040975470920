.b-cart_product {
	@include t-paragraph_2;

	align-items: start;
	display: grid;
	grid-template:
		[start details-s image-s qty-s total-s remove-s]
		auto
		[bundle-s details-e qty-e total-e]
		minmax(0, 1fr)
		[end bundle-e image-e remove-e]
		/
		[start image-s]
		165px
		[image-e]
		rh(4)
		[bundle-s details-s]
		minmax(0, 1fr)
		[details-e]
		rh(9)
		[qty-s]
		135px
		[bundle-e qty-e]
		rh(9)
		[total-s]
		130px
		[remove-s total-e]
		22px
		[end remove-e];

	@include media(md) {
		grid-template:
			[start details-s image-s remove-s]
			auto
			[details-e qty-s total-s]
			auto
			[bundle-s qty-e total-e]
			minmax(0, 1fr)
			[end bundle-e image-e remove-e]
			/
			[start image-s]
			100px
			[image-e]
			rh(4)
			[bundle-s details-s qty-s]
			135px
			[qty-e total-s]
			minmax(0, 1fr)
			[bundle-e details-e total-e]
			rh(4)
			[remove-s]
			22px
			[end remove-e];
	}

	@include media(sm) {
		grid-template:
			[start image-s details-s remove-s]
			auto
			[details-e qty-s]
			auto
			[qty-e total-s]
			auto
			[bundle-s total-e]
			auto
			[end bundle-e image-e remove-e]
			/
			[start image-s]
			100px
			[image-e]
			rh(3)
			[bundle-s details-s qty-s total-s]
			minmax(0, 1fr)
			[bundle-e details-e qty-e total-e]
			rh(3)
			[remove-s]
			22px
			[end remove-e];
	}

	&-price_column {
		grid-area: price-s/price-s/price-e/price-e;
	}

	&-image {
		grid-area: image-s/image-s/image-e/image-e;
	}

	&-image_link {
		display: block;
	}

	&-picture {
		@include g-product_image;

		img {
			@include g-product_image(img);
		}
	}

	&-details {
		grid-area: details-s/details-s/details-e/details-e;

		@include media(md-down) {
			margin-bottom: rh(4);
		}
	}

	&-qty {
		display: flex;
		grid-area: qty-s/qty-s/qty-e/qty-e;

		@include media(sm) {
			margin-bottom: rh(4);
		}

		&.m-preselected {
			@include media(xl) {
				font-size: 16px;
			}

			margin-top: rh(4);

			@include media(sm) {
				margin-top: 0;
			}
		}

		.b-product_quantity {
			width: 135px;
		}
	}

	&-qty_value {
		font-size: 18px;
		font-weight: 700;
		line-height: 24px;
	}

	&-total {
		grid-area: total-s/total-s/total-e/total-e;

		@include media(md) {
			align-self: center;
			margin-left: rh(4);
		}

		@include media(md-down) {
			align-items: baseline;
			display: flex;
		}

		.b-product_price {
			@include media(lg-up) {
				font-size: 18px;
			}
		}
	}

	&-total_label {
		display: none;

		@include media(md-down) {
			display: block;
			margin-right: rh(1);
		}
	}

	&-remove {
		grid-area: remove-s/remove-s/remove-e/remove-e;
	}

	&-total,
	&-remove {
		@include media(lg-up) {
			align-items: center;
			display: flex;
			height: 60px; //.b-product_quantity height
		}
	}

	&-bundle {
		grid-area: bundle-s/bundle-s/bundle-e/bundle-e;
		margin-top: rh(3);
	}

	&-bundle_control {
		@include icon($icon_name: arrow-down arrow-up, $icon-width: 10px, $icon-height: 6px, $position: after);

		align-items: center;
		cursor: pointer;
		display: flex;

		&[aria-expanded='true']::after {
			@include icon($icon_name: arrow-down arrow-up, $show: 2);
		}
	}

	&-bundle_link {
		@include t-link(default);
		@include t-link(hover);

		font-size: 16px;
		margin-right: rh(2);
	}

	&-bundle_items {
		@include g-accordion(content);

		&[aria-hidden='false'] {
			@include g-accordion(content, expanded);
			overflow:visible;
		}
	}

	&-error {
		@include g-message;
		@include g-message(error);

		grid-area: message-s/message-s/message-e/message-e;
		margin-top: rh(6);
	}

	&-error_accent_text {
		font-weight: 700;
	}

	.b-cart_bundle {
		margin-top: rh(6);
	}

	&.m-full_info {
		column-gap: rh(4);
		grid-template:
			[start details-s image-s price-s qty-s total-s]
			auto
			[bundle-s details-e price-e qty-e total-e]
			minmax(0, 1fr)
			[bundle-e image-e messsage-s]
			auto
			[end message-e]
			/
			[start image-s message-s]
			115px
			[image-e bundle-s details-s]
			minmax(0, 1fr)
			[details-e price-s]
			110px
			[price-e qty-s]
			70px
			[bundle-e qty-e total-s]
			170px
			[end total-e message-e];

		@include media(md) {
			grid-template:
				[start details-s image-s]
				auto
				[details-e]
				auto
				[price-s]
				auto
				[price-e total-s]
				auto
				[bundle-s total-e]
				minmax(0, 1fr)
				[bundle-e image-e message-s]
				auto
				[end message-e]
				/
				[start image-s message-s]
				100px
				[image-e bundle-s details-s price-s total-s]
				minmax(0, 1fr)
				[end bundle-e price-e details-e total-e message-e];
		}

		@include media(sm) {
			column-gap: rh(3);
			grid-template:
				[start details-s image-s]
				auto
				[details-e]
				auto
				[price-s]
				auto
				[price-e total-s]
				auto
				[bundle-s total-e]
				minmax(0, 1fr)
				[bundle-e image-e message-s]
				auto
				[end message-e]
				/
				[start image-s message-s]
				100px
				[image-e bundle-s details-s price-s qty-s total-s]
				minmax(0, 1fr)
				[end bundle-e details-e price-e qty-e total-e message-e];
		}
	}

	&.m-full_info &-qty {
		justify-content: center;

		@include media(md-down) {
			display: none;
		}
	}

	&.m-full_info &-price_column {
		@include media(md-down) {
			display: none;
		}

		.b-product_price {
			font-size: 16px;
			justify-content: center;
		}
	}

	&.m-full_info &-total {
		height: auto;
		justify-content: flex-end;

		@include media(md-down) {
			justify-content: flex-start;
			margin: 0;
		}

		.b-product_price {
			justify-content: flex-end;
		}
	}

	&.m-full_info .b-cart_product_details-attributes {
		@include media(md-down) {
			font-size: $size-font;
		}
	}

	&.m-full_info .b-minicart_product-subtotal_price {
		justify-content: flex-end;
	}
}
