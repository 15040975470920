.b-summary_table {
	font-size: 14px;
	letter-spacing: 0.2px;
	line-height: 1.75;
	text-align: left;
	width: 100%;

	&-name,
	&-value {
		font-weight: inherit;
		padding: rh(1 0);
	}

	&-value {
		padding-left: rh(1);
		text-align: right;
		white-space: nowrap;
	}

	&-item {
		display: flex;
		justify-content: space-between;

		&.m-discount {
			color: $color-accent;
		}

		&.m-total {
			border-top: 1px solid $color-order_summary-separator;
			font-size: 18px;
			font-weight: 700;
			margin-top: rh(3);
		}
	}

	&-item.m-total &-name,
	&-item.m-total &-value {
		padding: rh(4 0 0);
	}

	&-cellar {
		border-top: 1px solid;
		font-size: $size-font-alt;
		letter-spacing: 0.15px;
		padding-bottom: 8px;
		padding-top: 16px;
	}

	&-address {
		color: $color-shade_3;
		font-size: $size-font-sub-text;
		letter-spacing: 0.12px;
	}
}
