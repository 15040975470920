@charset "UTF-8";

@import 'main';

//COMMON blocks, Layout & Login page
@import '04-layouts/l-account';
@import '05-blocks/account/b-account_title';
@import '05-blocks/account/b-reset_password_success';
@import '05-blocks/account/b-code_field'; //Account verification step
@import '05-blocks/common/b-message';
@import '05-blocks/checkout/b-option_switch';
@import '05-blocks/checkout/b-address_selector';
@import '05-blocks/account/b-login_helpers'; // Remember me and forgot password
@import '05-blocks/account/b-cards_grid'; // Address book, payment methods
@import '05-blocks/account/b-user_greeting'; // Greeting above the navigation
@import '05-blocks/account/b-account_nav'; // Account navigation menu
@import '05-blocks/account/b-navigation_panel'; //Navigation panel for S and M viewports
@import '05-blocks/account/b-back_button'; // Verification step
@import '05-blocks/plp/b-load_more';
@import '05-blocks/plp/b-load_progress';

// PAGE Payments
@import '05-blocks/checkout/b-payment_option';
@import '05-blocks/checkout/b-expired_badge';
@import '05-blocks/common/b-payment_methods';

//PAGE Login
@import '05-blocks/account/b-social_login';
@import '05-blocks/account/b-account_benefits'; // Content asset. Create account tab

//PAGE Account overview
@import '05-blocks/account/b-account_card';

//PAGE Track order
@import '05-blocks/account/b-track_order';
@import '05-blocks/account/b-track_notification';

//PAGE Address book
@import '05-blocks/account/b-account_info_tile'; // Address tile

//PAGE Order history / Order details
@import '05-blocks/account/b-order_short_summary';
@import '05-blocks/account/b-order_info_card';
@import '05-blocks/account/b-order_history';
@import '05-blocks/account/b-order_details';
@import '05-blocks/cart/b-summary_table';
@import '05-blocks/cart/b-order_summary';
@import '05-blocks/cart/b-cart_table';
@import '05-blocks/cart/b-cart_product';
@import '05-blocks/cart/b-cart_product_details';
@import '05-blocks/cart/b-cart_bundle';
@import '05-blocks/checkout/b-summary_address';
@import '05-blocks/checkout/b-summary_payment';
@import '05-blocks/checkout/b-summary_group';
@import '05-blocks/checkout/b-checkout_products';
@import '05-blocks/checkout/b-payment_icon';

// Show page content after all
@import '05-blocks/page-show';

.cancelOrder-modal {
	@include media(md-up) {
		&.b-dialog.m-quick_view .b-dialog-window {
			width: 40%;
		}

		.renderTemplate {
			margin-left: 15%;
		}
	}

	&.b-dialog.m-quick_view .b-dialog-window {
		top: 10%;
		width: 90%;
	}

	.b-dialog-close {
		position: absolute;
		right: 40px;
		top: 40px;
	}

	.b-dialog-body {
		text-align: left;

		.cancel-order-FAQ {
			display: inline-block;
		}

		.cancelOrder-question {
			font-family: $font-alt;
			font-size: 16px;
			letter-spacing: 0.15px;
			line-height: 24px;

			a {
				cursor: pointer;
				font-family: $font-main;
				padding: 0 5px;
				text-decoration: underline;
			}
		}

		.cancelOrder-title {
			color: $color-primary;
			font-family: $font-main;
			font-size: 24px;
			font-weight: bold;
			letter-spacing: 0.23px;
			line-height: 32px;
			margin: 108px 0 8px;
		}

		select#cancelreason {
			border: 2px solid $color-primary;
			color: $color-select-option;
			font-family: $font-main;
			font-size: 16px;
			height: 56px;
			letter-spacing: 0.15px;
			line-height: 24px;
			margin: 8px 0;
			width: 100%;
		}

		.b-form-btn_alt {
			margin: 8px 0px;
		}

		.b-form-btn_main {
			background-color: $color-primary;
			color: $color-primary-background;
			margin: 8px 0;
		}

		.cancelOrderfailure-title {
			color: $color-primary;
			font-family: $font-main;
			font-size: 24px;
			font-weight: bold;
			letter-spacing: 0.23px;
			line-height: 32px;
			margin: 108px 0 24px;
		}

		.cancelOrderfailure-msg {
			font-family: $font-alt;
			font-size: 16px;
			letter-spacing: 0.15px;
			line-height: 24px;
			margin: 12px 0 41px;

			a {
				cursor: pointer;
				font-family: $font-main;
				padding: 0 4px;
				text-decoration: underline;
			}
		}
	}
}

.b-order_short_summary-list {
	@include media(md-up) {
		.cancelorder-modal {
			text-align: right;
		}
	}

	.cancelorder-modal {
		text-align: left;
	}
}

.cancelinfotime-msg {
	font-family: $font-alt;
	font-size: 12px;
	letter-spacing: 0.15px;
	margin-top: 8px;
}

.b-account_card {
	@include media(md-down) {
		.cancelinfotime-msg {
			text-align: center;
		}

		.cancelorder-modal .cancelinfotime-msg {
			text-align: left;
		}
	}

	&-defaultaddress {
		svg {
			height: 12px;
			width: 17px;
		}
	}
}

.b-order_short_summary-status-deleted {
	color: $color-error;
	font-family: $font-alt;
	font-size: 16px;
	font-weight: bold;
}

.deleted {
	color: $color-error;
}
