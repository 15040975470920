.b-summary_address {
	@include t-paragraph_3;

	&-default {
		align-items: center;
		display: flex;
		font-weight: 600;
		margin-top: rh(4);

		@include icon(checkmark, $icon-width: 13px, $icon-height: 13px) {
			margin-right: rh(1);
		}
	}
}
