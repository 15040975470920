.b-track_order {
	@include g-section_holder;

	font-size: 18px;
	letter-spacing: 0.2px;

	&-inner {
		margin: rh(6) auto rh(10) auto;
		max-width: 680px;

		@include media(lg-up) {
			margin: rh(18) auto rh(24) auto;
		}
	}

	&-form {
		margin: 0 auto;
		max-width: 450px;

		.b-form-btn_main {
			margin-top: rh(2);
		}
	}

	&-note {
		text-align: center;
	}

	&-sign_in {
		align-items: center;
		display: inline-flex;
		margin: rh(6 0);
	}

	&-link {
		@include t-link_1($color-visited: $color-text);

		font-size: 16px;
		font-weight: 600;
		margin-left: rh(4);

		&:hover {
			@include t-link_1($state: hover);
		}
	}

	.b-account_title {
		margin-bottom: rh(6);
	}
}
