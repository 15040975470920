.b-account_nav {
	&-item {
		border-top: 1px solid $color-divider;
		cursor: pointer;
		font-size: 16px;
		font-weight: 700;
		letter-spacing: 2.2px;
		padding: 0;
		text-transform: uppercase;
		transition: color $motion-fast;

		&:last-child {
			border-bottom: 1px solid $color-divider;
		}

		&.m-active {
			background: $color-account_nav-item__active-bg;
			color: $color-account_nav-item__active-text;
			font-weight: 700;
		}
	}

	&-item_link {
		display: block;
		padding: rh(5 4);
		position: relative;
		text-align: left;

		@include icon(arrow-right-thin, $position: after, $icon-height: 11px) {
			opacity: 0;
			position: absolute;
			right: rh(4);
			top: 50%;
			transform: translateY(-50%);
		}

		&:hover {
			text-decoration: none;
		}

		&:hover::after {
			opacity: 1;
		}
	}

	&-item.m-club &-item_text {
		@include icon(grape, $position:after, $icon-height: 2em) {
			color: $color-badge-2;
			display: inline-block;
			margin-left: rh(2);
			vertical-align: middle;
		}
	}

	&-item.m-active &-item_link::after {
		opacity: 1;
	}
}
