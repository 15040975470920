.b-order_summary {
	border: 1px solid $color-order_summary-border;

	& ~ & {
		margin-top: rh(10);
	}

	&-header {
		@include g-separator($color: $color-product_table-separator);

		display: flex;
		justify-content: space-between;
		padding: rh(4 5 3);
	}

	&-title {
		@include t-heading_6;
	}

	&-edit_button {
		@include t-link_4('edit');
	}

	&-content {
		padding: rh(4 5);
	}

	&-shipping,
	&-totals,
	&-cta_wrap {
		margin-bottom: rh(5);
	}

	.apple-pay-message {
		text-align: center;
		margin-bottom: rh(3);
	}

	&.m-cart_summary {
		border-width: 0 0 1px;
	}

	&.m-cart_summary &-header,
	&.m-cart_summary &-content {
		padding-left: 0;
		padding-right: 0;
	}

	&.m-cart_summary &-content {
		padding: 0;

		@include media(lg-up) {
			max-height: 620px;
			overflow: auto;
		}
	}

	&-apple_pay {
		&.hide-apple_pay {
			display: none;
		}

		&_format_button {
			background: var(--button_main-background_color, $color-primary-background);
		}
	}
}
