.b-expired_badge {
	background-color: $color-checkout-card_expired-bg;
	border-radius: 2px;
	color: $color-checkout-card_expired;
	font-size: 10px;
	font-weight: bold;
	height: 22px;
	line-height: 14px;
	padding: 4px rh(1);
}
