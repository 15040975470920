.b-track_notification {
	@include g-section_holder;
	@include g-section_holder(narrow);

	&-inner {
		@include g-message;
		@include g-message(promo);

		margin: rh(12) auto rh(6);

		@include media(sm) {
			flex-direction: column;
			margin: rh(6 0 4);
			padding: rh(3 4);
		}
	}

	&-title {
		margin-right: rh(4);
		padding-right: rh(4);
		position: relative;
		text-transform: uppercase;
		white-space: nowrap;

		@include media(sm) {
			margin: rh(1 0 2 0);
			padding-right: 0;
		}

		&::after {
			background: $color-divider;
			content: '';
			height: 12px;
			position: absolute;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
			width: 1px;

			@include media(sm) {
				display: none;
			}
		}
	}

	&-message {
		font-weight: 400;
		letter-spacing: 0.2px;
		text-transform: none;
	}

	&-image {
		display: inline-block;
		padding: 0 rh(2);
	}

	&-link {
		@include t-link_underlined($underline-offset: 0, $color-visited: $color-text, $font-weight: 700);
	}
}
