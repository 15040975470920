.b-social_login {
	&-header {
		font-size: 16px;
		letter-spacing: 2.4px;
		margin-bottom: rh(6);
		text-align: center;
		text-transform: uppercase;

		@include media(sm) {
			margin-bottom: rh(5);
		}
	}

	&-btns {
		display: flex;
	}

	&-btn {
		box-shadow: $depth-0;
		cursor: pointer;
		flex: 1;
		font-size: 14px;
		font-weight: 600;
		letter-spacing: 2px;
		padding: rh(3 6);
		text-align: center;
		text-transform: uppercase;
		transition: $motion-ease;
		transition-property: box-shadow;

		@include media(sm) {
			padding: rh(3 2);
		}

		&:hover {
			box-shadow: $depth-1;
		}

		svg {
			margin-right: rh(2);
		}
	}

	&-btn.m-facebook {
		background-color: $color-social_login__facebook-bg;
		color: $color-social_login__facebook-text;
		margin-right: rh(3);
	}

	&-btn.m-google {
		background: $color-social_login__google-bg;
		border: 1px solid $color-social_login__google-border;
	}

	&-text {
		color: $color-social_login__google-text;
	}
}
