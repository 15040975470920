.b-account_benefits {
	&-title {
		font-size: 18px;
		font-weight: 400;
		margin-bottom: rh(3);
	}

	&-list {
		@include t-list;

		margin-left: rh(2);
	}

	&.m-with_icons &-title {
		@include t-heading_5;
	}

	&.m-with_icons &-list {
		list-style-type: none;
		margin-left: 0;
	}

	&.m-with_icons &-item {
		margin-bottom: rh(2);
		padding-left: rh(5);
		position: relative;

		@include icon(checkmark, $icon-width: 13px, $icon-height: 13px) {
			left: 0;
			position: absolute;
			top: rh(1);
		}
	}
}
