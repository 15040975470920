.b-payment_option {
	--payment_option-side-offset: #{rh(5)};

	@include media(sm) {
		--payment_option-side-offset: #{rh(3)};
	}

	&-item:not(.m-zero_payment) {
		border: $global-line solid $color-divider;
		margin-bottom: rh(5);
	}

	&-title {
		@include g-separator($color: $color-divider);

		padding: rh(3) var(--payment_option-side-offset);
	}

	&-head {
		align-items: center;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		width: 100%;
	}

	&-label {
		font-weight: bold;
		text-transform: uppercase;
	}

	&-img {
		margin: rh(1 0);
	}

	&-content {
		@include g-accordion(content);
		&.m-opened {
			@include g-accordion(content, expanded);
			height: auto !important; // Used to override height set by JS logic
		}
	}

	.apple-pay-container {
		margin: rh(6);
	}

	.apple-pay-message {
		margin-bottom: rh(4);
	}

	&-content_inner.m-zero_payment {
		@include g-message;
		@include g-message(promo);
	}

	.b-form-set,
	.b-payment_options_group {
		margin: rh(6) var(--payment_option-side-offset) rh(2);
	}

	.b-form-line_exp_cvv {
		display: grid;
		gap: rh(0 3);
		grid-template-columns: 1fr 1fr;

		.f-field {
			margin-right: 0;
		}

		.f-field-caption {
			grid-column: 1/-1;
			margin: rh(-4) 0 var(--payment_option-side-offset);
		}

		.f-input_password-toggle_visibility {
			display: none;
		}
	}

	&-head .b-payment_methods {
		@include media(sm) {
			margin-top: rh(2);
			width: 100%;
		}
	}

	.b-payment_icon {
		height: 20px;
		width: 28px;
	}

	.b-form-back {
		margin-bottom: rh(5);
	}

	.b-message {
		margin-bottom: rh(4);
	}
}
